@import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap');
.service-title{
    font-family: 'Koulen', sans-serif;
    font-size: 100px;
  }
  @media(max-width:980px) {
    .service-title{
      font-size: 9.5vw;
    }

  }
  @media(max-width:450px) {
    .service-title{
      font-size: 11vw;
    }

  }