@import url('https://fonts.googleapis.com/css2?family=Electrolize&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap');


.blog-title {
    text-align: center;
    margin-top: 150px;
    margin-bottom: 60px;
    font-family: 'Koulen', sans-serif;
    /* text-shadow: -2px -2px 0 #ec5d24, 2px -2px 0 #ec5d24, -2px 2px 0 #ec5d24, 2px 2px 0 #ec5d24; */
    font-size: 120px;
  }
  
  .blog-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
  }
  .card-body {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    outline: none !important;
    transition: box-shadow 0.3s; /* Added transition property */
    border: none !important;
    border-width: 0 !important;
  }
  .card{
    border-width: 0px !important;
  }
  .card-body:focus,
  .card-body:hover {
    outline: none !important; /* Remove outline on focus */
  }
  
  .blog-card {
    width: 100%;
    margin-bottom: 40px;
    outline: none !important;
    height: 800px !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5); /* Increased shadow on hover */

    /* margin-left: -100px;
    margin-right: -100px; */
  }
  
  .blog-image {
    height: 400px;
    object-fit: cover;
  }
  
  .blog-date {
    font-style: italic;
    color: #888;
    /* position: absolute;
    bottom: 70px;
    left: 10px; */
  }
  @media(min-width:1200px){
  .blog-container{
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  }