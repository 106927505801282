@import url('https://fonts.googleapis.com/css2?family=Electrolize&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap');


.why-title{
    font-family: 'Koulen', sans-serif;
    /* text-shadow: -2px -2px 0 #ec5d24, 2px -2px 0 #ec5d24, -2px 2px 0 #ec5d24, 2px 2px 0 #ec5d24; */
    font-size: 8vw;
    border-bottom:5px solid black;
    color: #2488ec;
   
}
.why-subtitle{
    font-family: 'Koulen', sans-serif;
    /* font-weight: bolder; */
    font-size: 5vw;
    /* color: #ec2424; */
}

.equipment-name{
    text-align: center;
    font-family: 'Koulen', sans-serif;


}
.why-equip{
    font-family: 'Koulen', sans-serif;

}
.why-img{
    height: 300px;
    width: 400px;
    min-height:300px; 
    max-height:300px; 
    max-width: 400px;
    min-width: 400px;

  
}
@media(max-width:991px){
    .why-img{
       
        min-height:150px; 
        max-height:150px; 
        max-width: 200px;
        min-width: 200px;
        width: 200px;
        height: 150px;
      
    }
}
.why-sealtech-container{
    padding-top:100px; 
    padding-bottom:100px; 
    margin-top:200px; 
    margin-bottom:100px;
}
@media(max-width:991px){
    .why-sealtech-container{
        width: 100% !important;
       
    }
}
@media(min-width:1400px){
    .why-title{
        font-size: 160px;
        /* margin-bottom: 50px; */
    }
    .why-subtitle{
        font-size: 100px;
        margin-top: 50px;
        margin-bottom: 30px;
    }
    .why-description{
        font-size: 30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
       

    }
    .why-equip{
        font-family: 'Koulen', sans-serif;
        font-size: 60px;
        /* font-weight: bolder; */
    }
    .equipment-name{
        font-size: 22px;
        font-weight: bolder;
    }
}
@media (min-width: 1200px) and (max-width: 1399px) {
    .why-title{
        font-size: 160px;
    }
    .why-subtitle{
        font-size: 100px;
        margin-top: 50px;
        margin-bottom: 30px;
    }
    .why-description{
        font-size: 25.5px;
        font-family: 'Plus Jakarta Sans', sans-serif;
       

    }
    .why-equip{
        font-family: 'Electrolize', sans-serif;
        font-size: 60px;
        font-weight: bolder;
    }
    .equipment-name{
        font-size: 22px;
        font-weight: bolder;
    }
    .why-img{
        height: 225px;
        width: 325px;
        min-height:225px; 
        max-height:225px; 
        max-width: 325px;
        min-width: 325px;
    
      
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .why-title{
        font-size: 160px;
    }
    .why-subtitle{
        font-size: 100px;
        margin-top: 50px;
        margin-bottom: 30px;
    }
    .why-description{
        font-size: 21px;
        font-family: 'Plus Jakarta Sans', sans-serif;
       

    }
    .why-equip{
        font-family: 'Electrolize', sans-serif;
        font-size: 60px;
        font-weight: bolder;
    }
    .equipment-name{
        font-size: 22px;
        font-weight: bolder;
    }
    .why-img{
        height: 200px;
        width: 300px;
        min-height:200px; 
        max-height:200px; 
        max-width: 300px;
        min-width: 300px;
    
      
    }
}
@media(max-width:767px){
    .why-img{
        width: 100%;
        height: 300px !important;
        min-height: 300px;
        max-height: 500px;
        margin-bottom: 20px;
    }
}