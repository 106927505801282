@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap');

.review-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.review-row-mobile {
  display: flex;
  justify-content: center;
}

.arrow-row {
  margin-top: 10px;
}

.arrow-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
}

.arrow-icon svg {
  font-size: 20px;
}

.arrow-icon.left {
  margin-right: 5px;
}

.left-icon {
  position: absolute;
  /* right: 50px;
  bottom: 100px; */
}

.right-icon {
  position: absolute;
  /* left: 50px;
  bottom: 100px; */
}

.arrow-icon.right {
  margin-left: 5px;
}



.review-container {
 background-color: #f3f3f3;
  height: 666px;
  min-width: 100%;
  position: relative;
  z-index: 11;
  padding: 100px;
  margin-bottom: 80px;
  bottom: 200px;
  margin-top: 0;
  /* padding-left: 100px;
  padding-right: 100px;
  style={{ marginBottom: '80px', position: 'relative', bottom: 200, marginTop: '0px' }} */
}

.review-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(255, 165, 0, 0.5); */
  /* z-index: -1; */
  pointer-events: none;
}

.profile-pic {
  position: absolute;
  top: 5px;
  left: 10;
  
}

.star-icon {
  color: #fdd835;
  margin-right: 2px;
}

.card-text-content {
  max-height: 50px;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.card-text-content.expanded {
  max-height: none;
}

.read-more-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  padding: 0;
  margin-top: 5px;
}

.review-card {
  height: 100%;
  overflow: hidden; /* Add this line */
}

.card-text-content {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.card-text-content.expanded {
  -webkit-line-clamp: unset;
}

.read-more-button {
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
}

.review-title {
  font-family: 'Koulen', sans-serif;
  font-size: 80px;
  margin-bottom: 30px;
}

@media(max-width:1240px) {
  .review-title{
    font-size: 65px;
  }
}
@media(max-width:1080px) {
  .review-title{
    font-size: 50px;
  }
}
@media(max-width:875px) {
  .review-title{
    font-size: 45px;
  }
}
@media(max-width:820px) {
  .review-title{
    font-size: 40px;
  }
}
@media(max-width:740px) {
  .review-title{
    font-size: 35px;
    white-space: nowrap;
    text-align: center;
  }

}
@media(max-width:660px) {
  .review-title{
    font-size: 30px;
    white-space: nowrap;
    text-align: center;
  }

}
@media(max-width:575px) {
  .review-title{
    font-size: 25px;
    white-space: nowrap;
    text-align: center;
  }

}
@media(max-width:520px) {
  .review-title{
    font-size: 20px;
    white-space: nowrap;
    text-align: center;
  }

}
@media (max-width: 1285px) {
  .review-row-mobile .col-md-4 {
    display: none;
  }
  .review-row-mobile .col-md-4:nth-child(2) {
    display: block;
    width: 100%;
  }
  .card-text-content{
    font-size: 24px;
    max-height: 75px;
  }
  .review-name{
    font-size: 24px;
  }
}
@media(max-width: 800px) {
  .card-text-content{
    font-size: 18px;
    max-height: 60px;
  }
  .review-name{
    font-size: 18px;
  }
}

@media(max-width:580px) {
  .card-text-content{
    font-size: 14px;
    max-height: 45px;
  }
  .review-name{
    font-size: 14px;
  }
}
@media(max-width:417px) {
  .card-text-content{
    font-size: 12px;
    max-height: 50px;
  }
  .review-name{
    font-size: 12px;
  }
  
}
@media(max-width: 485px) {
  .review-row-mobile{
   padding:-60px
    /* justify-content: center;
    align-items: center; */
   
  }
  .review-container{
    padding-left: -100px;
    padding-right: -100px;
    overflow: hidden;
  }
  .cardss{
  position: absolute;
  }
  .review-title{
    position: absolute;
    
    top: 10px;
    font-size: 6.5vw;
    left:17%;
    
  }
}
/* Existing styles... */
